var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"pt-2",attrs:{"no-gutters":"","justify":"center"}},[_c('v-slide-group',{attrs:{"hidden":_vm.smallWidth,"show-arrows":!_vm.smallWidth}},_vm._l((_vm.servises),function(item,i){return _c('v-slide-item',{key:i},[_c('v-card',{class:{
          'ml-2 mr-2': _vm.smallWidth,
          'ml-5 mr-5': !_vm.smallWidth
        },attrs:{"color":item.color,"width":_vm.cardWidth,"height":_vm.cardHeight}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"ma-0 pa-0",class:{
            'pl-3': !_vm.smallWidth,
            'pl-': _vm.smallWidth
          },attrs:{"cols":"4","sm":"5"}},[_c('v-img',{attrs:{"src":item.image,"width":_vm.smallWidth ? item.image_size.width/2 : item.image_size.width,"height":_vm.smallWidth ? item.image_size.height/2 : item.image_size.height}})],1),_c('v-col',{staticClass:"ma-0 pa-0",class:{
            'pt-3': _vm.smallWidth,
            'pt-6': !_vm.smallWidth,
            'pr-1': !_vm.smallWidth
          },style:(_vm.textColStyle)},[_c('div',{staticClass:"text-h6 mb-1 font-weight-bold",class:{
                'text-subtitle-2': _vm.smallWidth
              },style:(_vm.titleStyle)},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"text-subtitle-1 font-weight-medium",class:{
                'text-caption': _vm.smallWidth
              },style:(_vm.bodyTextStyle)},[_vm._v(" "+_vm._s(item.text)+" ")])])],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }